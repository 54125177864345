import Image from "next/image";
import FeatureCard from "../components/FeatureCard";
import { useState, useEffect } from "react";
import { features } from "../utils";

const CarouselDesktop = (): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [paused, setPaused] = useState(false);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
    }, 5000)

      if (paused) {
        clearInterval(interval)
      }

      return () => {
          clearInterval(interval)
      }
  }, [paused]);

  const onMouseEnter = (selectedIndex: number) => {
    setCurrentIndex(selectedIndex);
    setPaused(true);
  };

  return (
      <div className="flex flex-col md:flex-row justify-between items-center">
        {/* LHS */}
        <div className="flex-1">
            {features.map((feature, index) => (
              <FeatureCard 
                key={index} 
                index={index}
                title={feature.title} 
                subtitle={feature.subtitle} 
                active={index === currentIndex} 
                onMouseEnter={onMouseEnter}
                onMouseLeave={() => setPaused(false)}
              />
            ))}
        </div>

        {/* RHS */}
        <div className="relative h-64 md:h-[20.5rem] lg:h-[24em] xl:h-[26em] flex-1">
        {features.map((feature, index) => (
          <>
            <div
              key={index}
              className={`absolute -right-[50%] transition-opacity z-30 ${
                index === currentIndex ? "opacity-100" : "opacity-0"
              } inset-0 duration-1000 ${
                index === currentIndex ? "delay-500" : ""
              }`}
            >
              <Image
                src={feature.image}
                alt="Feature image"
                objectFit="contain"
                priority
                layout="fill"
                className="z-30"
              />
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default CarouselDesktop;
